export default {
  saluation: '',
  name: '',
  email: '',
  phone: '',
  requestTestDrive: false,
  requestFinancingOffer: false,
  requestLeasingOffer: false,
  requestCarSubscription: false,
  requestTradeInOffer: false,
  isTermsAndPrivacyAccepted: false,
  newField: [],
};
